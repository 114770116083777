import { color } from '../utils/utils';
import styled, { css } from 'styled-components';
import transientProps from '../../../utils/transientProps';
import Dropdown from '../../../components/Dropdown';

export const PaginationContainer = styled('div').withConfig(transientProps)<{
  $hasLimit: boolean;
  $hasPages: boolean;
}>`
  flex: none;
  display: inline-flex;

  ul {
    display: inline-flex;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      flex: none;
      display: inline-flex;

      & + li {
        margin-left: 12px;
      }

      &.selected {
        a {
          background-color: ${color('primary.main')};
          color: ${color('common.white')};
        }
      }

      a {
        flex: none;
        outline: none;
        padding: 10px 16px;
        background-color: ${color('primary.light')};
        color: ${color('primary.main')};
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  ${({ $hasLimit, $hasPages }) =>
    $hasLimit &&
    css`
      /* TODO: вынести в основные стили */
      margin-top: 20px;

      display: flex;
      align-items: center;
      justify-content: ${$hasPages ? 'space-between' : 'flex-end'};
    `}
`;

export const PageLimit = styled(Dropdown)`
  & .Dropdown__control {
    border: none;
    background-color: ${color('primary.main')};

    & span {
      color: white;
      font-weight: 600;
    }

    & svg path {
      color: white;
    }
  }
`;
