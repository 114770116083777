import { debounce } from 'throttle-debounce';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import OffersApi from '../api/offers';
import { mapOffer } from '../maps/offersMap';
import { OfferLanding } from '../types/offers';
import { ApiOffersGet } from '../types/api/offers';
import { CommonFilterProps } from '../types/utils';
import { DropdownOption } from './Dropdown';
import useDropdownOptions from '../bit/components/dropdown/useDropdownOptions';
import { StyledDropdown } from '../pages/ReportsPage/styled';

import useAwaitCallback from '../hooks/useAwaitCallback';

import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import {
  DROPDOWNS_SAVE_KEYS,
  getDataFromLocalStorage
} from '../utils/generateDropdownsStorageKey';

interface PropsWithLandings extends CommonFilterProps {
  landing: CommonFilterProps['value'];
  onChangeLanding: CommonFilterProps['onChange'];
}

type Props = (CommonFilterProps | PropsWithLandings) & {
  withSavingData?: boolean;
};

const OffersDropdown = ({
  value,
  onChange,
  formLabel,
  withSavingData,
  ...restProps
}: Props) => {
  const { t } = useTranslation();

  const [offerList, setOfferList] = useState(
    getDataFromLocalStorage<DropdownOption>(
      withSavingData,
      DROPDOWNS_SAVE_KEYS.offer
    )
  );

  const [offersLandings, setOffersLandings] = useState(
    getDataFromLocalStorage<OfferLanding>(
      withSavingData,
      DROPDOWNS_SAVE_KEYS.landing
    )
  );

  const [fetchOffersList, isFetchingOffers] = useAwaitCallback(
    async (params?: ApiOffersGet) => {
      const response = await OffersApi.getOffers({ limit: 10, ...params });

      const array = response.data.data
        .map(mapOffer)
        .filter((offer) => offer.canPromote)
        .map((a) => ({ value: a.id, name: a.title, offer: a }));

      setOfferList(array);
    },
    []
  );

  useEffect(() => {
    if (withSavingData) {
      return;
    }

    fetchOffersList();
  }, [fetchOffersList, withSavingData]);

  const debouncedOffersSearch = useMemo(
    () =>
      debounce(300, false, async (search: string) => {
        fetchOffersList({ search });
      }),
    [fetchOffersList]
  );

  const onChangeOfferSearchInput = useCallback(
    (search: string) => {
      debouncedOffersSearch(search);
    },
    [debouncedOffersSearch]
  );

  useEffect(() => {
    if (!('onChangeLanding' in restProps)) return;

    const matchOfferOption = offerList.find(
      (option) => option.offer.id === Number(value)
    );

    if (matchOfferOption) {
      setOffersLandings(matchOfferOption.offer.landings);
    }
  }, [setOffersLandings, offerList, value, restProps]);

  useDidUpdateEffect(() => {
    if (!('onChangeLanding' in restProps)) return;
    restProps.onChangeLanding(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const offersLandingsOptions = useDropdownOptions(
    offersLandings,
    'title',
    'id'
  );

  return (
    <>
      <StyledDropdown
        savedKey={DROPDOWNS_SAVE_KEYS.offer}
        withInitialSetSearchValue={withSavingData}
        clearable
        searchable
        isSearchableForApi
        label={t('offers.offer')}
        value={value}
        onChange={onChange}
        options={offerList}
        formLabel={formLabel}
        onInput={onChangeOfferSearchInput}
        isFetchingForApi={isFetchingOffers}
      />

      {value && 'onChangeLanding' in restProps && (
        <StyledDropdown
          savedKey={DROPDOWNS_SAVE_KEYS.landing}
          withInitialSetSearchValue={withSavingData}
          label={t('offers.landing')}
          value={restProps.landing}
          options={offersLandingsOptions}
          onChange={restProps.onChangeLanding}
        />
      )}
    </>
  );
};

export default OffersDropdown;
