import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TableProps } from '../../bit/components/table';

import { Statistic } from '../../types/statistics';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchStatistics,
  selectStatistics
} from '../../redux/slices/statisticsSlice';

import { getDate, orNull } from '../../resources/functions';

import Loader from '../Loader/Loader';
import { AppDispatch } from '../../types';
import Icon from '../../bit/components/icon';
import chevronRightIcon from '../../images/chevronRight.svg';
import SupportManagerialPageHead from '../../pages/SupportManagerialPage/components/SupportManagerialPageHead';
import { useQueryParams, withDefault, StringParam } from 'use-query-params';
import { HeaderFiltrationRow } from '../../pages/ReportsPage/styled';
import { DropdownOnChange } from '../Dropdown';

import {
  ApiStatisticsGet,
  ApiStatisticFilters
} from '../../types/api/statistics';
import useAwaitCallback from '../../hooks/useAwaitCallback';
import useStatisticPageSubIds from '../../hooks/useStatisticPageSubIds';

import StatisticPageSubIds from '../StatisticPageSubIds';
import StatisticPageType from '../StatisticPageType';
import StatisticPageCountry from '../StatisticPageCountry';
import getTableStatisticGroupTitle from './getTableStatisticGroupTitle';
import getTableStatisticKey from './getTableStatisticKey';
import {
  CellText,
  StyledExpandButton,
  StyledTable
} from './TableStatisticStyled';
import OffersDropdown from '../OffersDropdown';
import Notification from '../Notification';
import Block from '../Block';
import { ReportsTableProps } from '../../pages/ReportsPage/ReportsPageTypes';

interface ExpandedRow {
  isExpanded?: boolean;
  isLoading?: boolean;
  date: string;
  rows: Statistic[];
}

const MIN_WIDTH_FIRST_COLUMN = 172;

const isExpandedItem = (item: any): item is ExpandedRow => {
  return item && typeof item.isExpanded !== 'undefined';
};

const TableStatistics = ({ dateFilter: { from, to } }: ReportsTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [expandedRows, setExpandedRows] = useState<ExpandedRow[]>([]);

  const statistics = useSelector(selectStatistics);
  const {
    onChangeAffiliateParam,
    onChangeSubId,
    affParam2,
    affParam5,
    subId1,
    subId2,
    subId3,
    subId4,
    subId5
  } = useStatisticPageSubIds();

  const [query, setQuery] = useQueryParams({
    type: withDefault(StringParam, 'day'),
    offers: withDefault(StringParam, undefined),
    landing: withDefault(StringParam, undefined),
    countries: withDefault(StringParam, undefined)
  });

  const { type, offers, landing, countries } = query;

  const [fetchData, isLoading] = useAwaitCallback(
    async (params: ApiStatisticsGet) => {
      await dispatch(fetchStatistics(params));
    },
    [dispatch]
  );

  const params = useMemo(() => {
    const result: ApiStatisticFilters = {
      offers,
      countries,
      offersLinks: landing,
      affiliatesSubids1: subId1,
      affiliatesSubids2: subId2,
      affiliatesSubids3: subId3,
      affiliatesSubids4: subId4,
      affiliatesSubids5: subId5,
      affiliatesParams2: affParam2,
      affiliatesParams5: affParam5
    };
    return result;
  }, [
    countries,
    landing,
    offers,
    subId1,
    subId2,
    subId3,
    subId4,
    subId5,
    affParam2,
    affParam5
  ]);

  useEffect(() => {
    const queries: ApiStatisticsGet = {
      to,
      from,
      breakdowns: type,
      ...params
    };

    fetchData(queries);
  }, [fetchData, from, params, to, type]);

  useEffect(() => {
    setExpandedRows([]);
  }, [statistics]);

  const handleHoursOfDayDownload = useCallback(
    (date: string) => async () => {
      const queries = { from: date, to: date, breakdowns: 'hour', ...params };
      const data = await dispatch(fetchStatistics(queries, true));

      if (data) {
        setExpandedRows((state) => {
          const newArray = [...state];
          const index = state.findIndex((a) => a.date === date);

          if (index >= 0) {
            const items = [...data.rows];

            // NOTE: Разворачивание часов в обратном порядке
            // По дефолту, от бэка - от 24 к 0
            // items.reverse();

            newArray[index].rows = items;
            newArray[index].isLoading = false;
          }

          return newArray;
        });
      } else {
        Notification.show({ message: 'Что-то пошло не так', type: 'error' });
      }
    },
    [dispatch, params]
  );

  const toggleRow = useCallback(
    (date: string) => () => {
      setExpandedRows((state) => {
        const newArray = [...state];
        const index = state.findIndex((a) => a.date === date);

        if (index >= 0) {
          newArray[index] = {
            ...newArray[index],
            isExpanded: !newArray[index].isExpanded
          };
        } else {
          newArray.push({
            date,
            rows: [],
            isLoading: true,
            isExpanded: true
          });
        }

        return newArray;
      });
    },
    []
  );

  const tableOptions = React.useMemo<TableProps['options']>(() => {
    const options = [
      { dataKey: 'clicks', title: t('statistics.uniqueClicks') },
      { dataKey: 'cpl', title: t('statistics.cpl') },
      { dataKey: 'cpa', title: t('statistics.cpa') },
      { dataKey: 'ratioCPL', title: t('statistics.ratioCPL') },
      { dataKey: 'ratioCPA', title: t('statistics.ratioCPA') },
      { dataKey: 'epc', title: t('statistics.epc') },
      { dataKey: 'payout', title: t('statistics.payout') },
      { dataKey: 'revsharePayout', title: t('statistics.revsharePayout') }
    ];

    if (type) {
      const groupCellTitle = getTableStatisticGroupTitle(t, type);

      if (groupCellTitle) {
        options.unshift({ dataKey: 'group', title: groupCellTitle });
      }
    }

    return options;
  }, [type, t]);

  const renderGroupCell = React.useCallback(
    (item: Statistic, isHours?: boolean) => {
      if (type === 'day' && item.date) {
        if (isHours) {
          return (
            <CellText variant='subtitle2' size={14}>
              {item.hour}
            </CellText>
          );
        }

        const isExpanded = Boolean(
          expandedRows.find((a) => a.date === item.date)?.isExpanded
        );

        return (
          <CellText variant='subtitle2' size={14}>
            <StyledExpandButton
              $isExpanded={isExpanded}
              onClick={toggleRow(item.date)}
            >
              <Icon size={20} color='inherit' src={chevronRightIcon} />
            </StyledExpandButton>

            {getDate(new Date(item.date), 'DD.MM.YYYY')}
          </CellText>
        );
      } else if (type) {
        const key = getTableStatisticKey(type);

        return (
          <CellText variant='subtitle2' size={14}>
            {item[key as keyof Statistic]}
          </CellText>
        );
      }
    },
    [expandedRows, toggleRow, type]
  );

  const rows = useMemo(() => {
    if (type === 'common') {
      return [statistics.totals];
    }

    const intermediateRows = (statistics.rows || []).map((a) => {
      const expandedRow = expandedRows.find((b) => a.date === b.date);

      if (expandedRow && expandedRow.isExpanded) {
        return [a, expandedRow];
      }

      return a;
    });

    const result = intermediateRows.flat(1);
    result.push(statistics.totals);

    return result;
  }, [expandedRows, statistics.rows, statistics.totals, type]);

  const renderRow = useCallback(
    (item: Statistic, key: string, isTotalRow: boolean, isHours?: boolean) => {
      return {
        key,
        group: renderGroupCell(item, isHours),
        clicks: (
          <CellText isTotal={isTotalRow} variant='subtitle2' size={14}>
            {item.clicks.unique}
          </CellText>
        ),
        cpl: (
          <CellText isTotal={isTotalRow} variant='subtitle2' size={14}>
            {item.cpl}
          </CellText>
        ),
        cpa: (
          <CellText isTotal={isTotalRow} variant='subtitle2' size={14}>
            {item.cpa}
          </CellText>
        ),
        payout: (
          <CellText isTotal={isTotalRow} variant='subtitle2' size={14}>
            {item.payout.total}$
          </CellText>
        ),
        revsharePayout: (
          <CellText isTotal={isTotalRow} variant='subtitle2' size={14}>
            {item.payout.revshare}$
          </CellText>
        ),
        epc: (
          <CellText isTotal={isTotalRow} variant='subtitle2' size={14}>
            {orNull(item.payout.total / item.clicks.unique).toFixed(2)}
          </CellText>
        ),
        ratioCPL: (
          <CellText isTotal={isTotalRow} variant='subtitle2' size={14}>
            {orNull((item.cpl / item.clicks.unique) * 100).toFixed(2)} %
          </CellText>
        ),
        ratioCPA: (
          <CellText isTotal={isTotalRow} variant='subtitle2' size={14}>
            {orNull((item.cpa / item.clicks.unique) * 100).toFixed(2)} %
          </CellText>
        )
      };
    },
    [renderGroupCell]
  );

  const tableRows = useMemo<TableProps['rows']>(() => {
    const items = [];

    for (let i = 0; i < rows.length; i++) {
      const item = rows[i];
      const isTotalRow = rows.length - 1 === i;

      if (isExpandedItem(item)) {
        const key = `statistic-expanded-${i}`;

        if (item.isLoading) {
          items.push({
            key,
            group: (
              <Loader isStatic onMount={handleHoursOfDayDownload(item.date)} />
            )
          });
        } else {
          const expandedRows = item.rows.map((a, j) =>
            renderRow(a, `${key}-item-${j}`, false, true)
          );

          items.push(...expandedRows);
        }
      } else {
        items.push(renderRow(item, `statistic-${i}`, isTotalRow));
      }
    }

    return items;
  }, [handleHoursOfDayDownload, renderRow, rows]);

  const createDropdownHandler = useCallback(
    (property: keyof typeof query): DropdownOnChange => (value) => {
      setQuery({ [property]: value || undefined });
    },
    [setQuery]
  );

  return (
    <Block disablePadding fullWidth>
      <SupportManagerialPageHead>
        <HeaderFiltrationRow>
          <StatisticPageType
            value={type}
            onChange={createDropdownHandler('type')}
          />
          <OffersDropdown
            withSavingData
            value={offers}
            landing={landing}
            onChange={createDropdownHandler('offers')}
            onChangeLanding={createDropdownHandler('landing')}
          />
          <StatisticPageCountry
            withSavingData
            value={countries}
            onChange={createDropdownHandler('countries')}
          />
        </HeaderFiltrationRow>

        <HeaderFiltrationRow>
          <StatisticPageSubIds
            subId1={subId1}
            subId2={subId2}
            subId3={subId3}
            subId4={subId4}
            subId5={subId5}
            affParam2={affParam2}
            affParam5={affParam5}
            onChange={onChangeSubId}
            onChangeAffiliateParam={onChangeAffiliateParam}
          />
        </HeaderFiltrationRow>
      </SupportManagerialPageHead>

      <StyledTable
        fullWidth
        rows={tableRows}
        isLoading={isLoading}
        options={tableOptions}
        isEmpty={!tableRows.length}
        minWidthFirstColumn={MIN_WIDTH_FIRST_COLUMN}
      />
    </Block>
  );
};

export default TableStatistics;
