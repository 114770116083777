import {
  mapStatistic,
  mapStatisticConversion,
  mapStatisticClick
} from './../../maps/statisticsMap';
import {
  ApiStatisticsClicksGet,
  ApiStatisticsConversionsGet
} from './../../types/api/statistics';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';

import Api from '../../api';
import StatisticsApi from '../../api/statistics';

import {
  Statistic,
  StatisticClick,
  StatisticConversion
} from '../../types/statistics';
import { ApiStatisticsGet } from '../../types/api/statistics';
import { AxiosError } from 'axios';
import { DEFAULT_DATA_WITH_PAGINATION } from '../../resources/constants';
import { ApiResponseWithPagination } from '../../types/api';

interface StatisticsState {
  clicks: ApiResponseWithPagination<StatisticClick>;
  conversions: ApiResponseWithPagination<StatisticConversion>;
  statistics: {
    rows: Statistic[];
    totals: Statistic;
    breakdownsColumns: unknown[];
  };
}

export const EMPTY_STATISTIC: Statistic = {
  date: '',
  cpa: 0,
  upsale: 0,
  cpl: 0,
  payout: {
    total: 0,
    approved: 0,
    revshare: 0,

    cpl_total: 0,
    cpa_total: 0
  },
  clicks: {
    total: 0,
    unique: 0
  }
};

const initialState: StatisticsState = {
  clicks: DEFAULT_DATA_WITH_PAGINATION,
  conversions: DEFAULT_DATA_WITH_PAGINATION,
  statistics: {
    rows: [],
    breakdownsColumns: [],
    totals: EMPTY_STATISTIC
  }
};

const statisticsSlice = createSlice({
  name: 'Statistics',
  initialState,
  reducers: {
    setStatistics: (state, action) => {
      state.statistics = action.payload;
    },
    setStatisticsClicks: (state, action) => {
      state.clicks = action.payload;
    },
    setStatisticsConversion: (state, action) => {
      state.conversions = action.payload;
    }
  }
});

const {
  setStatistics,
  setStatisticsClicks,
  setStatisticsConversion
} = statisticsSlice.actions;

export const fetchStatistics = (
  params: ApiStatisticsGet,
  isLocalResponse?: boolean
): AppThunk<Promise<undefined | StatisticsState['statistics']>> => async (
  dispatch
) => {
  try {
    const response = await StatisticsApi.getStatictics(params);

    const payload = {
      totals: mapStatistic(response.data.data.totals || {}),
      rows: (response.data.data.rows || []).map(mapStatistic),
      breakdownsColumns: response.data.data.breakdownsColumns || []
    };

    if (isLocalResponse) {
      return payload;
    }

    dispatch(setStatistics(payload));
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const fetchStatisticsConversion = (
  params: ApiStatisticsConversionsGet
): AppThunk => async (dispatch) => {
  try {
    const response = await StatisticsApi.getStaticticsConversions(params);

    dispatch(
      setStatisticsConversion({
        data: response.data.data.map(mapStatisticConversion),
        meta: response.data.meta
      })
    );
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const fetchStatisticsClicks = (
  params: ApiStatisticsClicksGet
): AppThunk => async (dispatch) => {
  try {
    const response = await StatisticsApi.getStaticticsClicks(params);
    dispatch(
      setStatisticsClicks({
        data: response.data.data.map(mapStatisticClick),
        meta: response.data.meta
      })
    );
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const selectStatistics = (state: RootState) =>
  state.statistics.statistics;
export const selectStatisticsClicks = (state: RootState) =>
  state.statistics.clicks;
export const selectStatisticsConversions = (state: RootState) =>
  state.statistics.conversions;

export default statisticsSlice.reducer;
