import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import cardIcon from '../images/sidebar/card.svg';
import graphIcon from '../images/sidebar/graph.svg';
import mainIcon from '../images/sidebar/monitor.svg';
import profileIcon from '../images/sidebar/profile.svg';
import profileCheckIcon from '../images/sidebar/profileCheck.svg';
import twoProfilesIcon from '../images/sidebar/twoProfiles.svg';
import appsIcon from '../images/sidebar/phone.svg';

import AppsPage from '../pages/AppsPage';
import MainPage from '../pages/MainPage';
import OfferPage from '../pages/OfferPage';
import OffersPage from '../pages/OffersPage';
import ProfilePage from '../pages/ProfilePage';
import BillingPage from '../pages/BillingPage';
import EmployeesPage from '../pages/EmployeesPage';
import ReportsPage from '../pages/ReportsPage';

import UnverifiedUserPage from '../pages/UnverifiedUserPage';
import { selectCompanyStatus } from '../redux/slices/userSlice';
import { CompanyStatuses } from '../types';
import useSections from './useSections';

export type RoutesPaths =
  | 'main'
  | 'profile'
  | 'employees'
  | 'offers'
  | 'offer'
  | 'reports'
  | 'billing';

export interface RouteConfig {
  title?: string;
  icon?: string;
  path: string | string[];
  component: (props: any) => JSX.Element | null;
  exact?: boolean;
}

export type GetRoutePath = (
  routeId: RoutesPaths,
  replace?: string | number | { [key: string]: string },
  query?: Record<string, any>
) => string;

const useRoutes = (): {
  routes: { [key in RoutesPaths]: RouteConfig };
  getRoutePath: GetRoutePath;
} => {
  const { t } = useTranslation();
  const { checkSection } = useSections();
  const companyStatus = useSelector(selectCompanyStatus);

  const routes = React.useMemo(() => {
    const result = {
      main: {
        path: '/',
        icon: mainIcon,
        title: t('sidebar.main'),
        component: MainPage,
        exact: true
      },
      reports: {
        path: '/reports',
        icon: graphIcon,
        title: t('sidebar.reports'),
        component: ReportsPage,
        exact: true
      },
      offers: {
        path: '/offers',
        icon: profileCheckIcon,
        title: t('sidebar.offers'),
        component: OffersPage,
        exact: true
      },
      offer: {
        path: '/offers/:id',
        component: OfferPage,
        exact: true
      },
      billing: {
        path: '/billing',
        icon: cardIcon,
        title: t('sidebar.billing'),
        component: BillingPage,
        exact: true
      },
      employees: {
        path: '/employees',
        icon: twoProfilesIcon,
        title: t('sidebar.employees'),
        component: EmployeesPage,
        exact: true
      },
      apps: {
        path: '/apps',
        icon: appsIcon,
        title: t('sidebar.apps'),
        component: AppsPage,
        exact: true
      },
      profile: {
        path: '/profile',
        icon: profileIcon,
        title: t('sidebar.profile'),
        component: ProfilePage,
        exact: true
      }
    };

    if (companyStatus !== CompanyStatuses.active) {
      // блокировка страниц для неактивной компании
      result.offers.component = UnverifiedUserPage;
      result.billing.component = UnverifiedUserPage;
      result.reports.component = UnverifiedUserPage;

      delete result.offer;
    }

    if (!checkSection('reports')) delete result.reports;
    if (!checkSection('offers')) delete result.offers;
    if (!checkSection('offers')) delete result.offer;
    if (!checkSection('billing')) delete result.billing;
    if (!checkSection('employees')) delete result.employees;
    if (!checkSection('apps')) delete result.apps;

    return result;
  }, [checkSection, companyStatus, t]);

  const getRoutePath = React.useCallback<GetRoutePath>(
    (routeId, replace, query) => {
      const route = routes[routeId];

      if (route) {
        let path = Array.isArray(route.path) ? route.path[0] : route.path;

        if (replace) {
          if (['string', 'number'].includes(typeof replace)) {
            path = path.replace(':id', replace);
          } else {
            const searchValue = Object.keys(replace)[0];
            const replaceValue = Object.values(replace)[0];

            path = path.replace(searchValue, replaceValue);
          }
        }

        if (query) {
          path += `?${queryString.stringify(query)}`;
        }

        return path;
      }

      return '';
    },
    [routes]
  );

  return {
    routes,
    getRoutePath
  };
};

export default useRoutes;
