import React, { useCallback } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useFromToDatePicker from '../../hooks/useFromToDatePicker';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault
} from 'use-query-params';

import Page from '../../components/Page';
import Grid from '../../components/Grid';
import TableClicks from '../../components/TableClicks';
import TableStatistics from '../../components/TableStatistics';
import TableConversions from '../../components/TableConversions';

import { ReportsPageTabs } from './ReportsPageTypes';
import { MANAGERIAL_PAGE_HEAD } from '../../resources/constants';

import {
  StyledTab,
  HeaderBody,
  HeaderTabs,
  HeaderContainer,
  StyledTabContainer
} from './styled';

const fromLastMonth = moment().subtract(1, 'month');

const ReportsPage = () => {
  const { t } = useTranslation();
  const { DatePicker, from, to } = useFromToDatePicker(fromLastMonth, true);

  const [{ activeTab }, setQuery] = useQueryParams({
    activeTab: withDefault(StringParam, ReportsPageTabs.statistic)
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setQueryPagination] = useQueryParams({
    page: NumberParam,
    limit: NumberParam
  });

  const onChangeTab = useCallback(
    (newTab: string) => {
      setQuery({ activeTab: newTab });
      setQueryPagination({ limit: undefined, page: undefined });
    },
    [setQuery, setQueryPagination]
  );

  return (
    <Page>
      <HeaderContainer
        title={t('sidebar.reports')}
        additionalTitle={<DatePicker />}
      >
        <HeaderBody>
          <div id={MANAGERIAL_PAGE_HEAD} />

          <HeaderTabs>
            <StyledTabContainer activeId={activeTab} onChange={onChangeTab}>
              <StyledTab variant='open' id={ReportsPageTabs.statistic}>
                {t('tabs.statistic')}
              </StyledTab>
              <StyledTab variant='open' id={ReportsPageTabs.conversion}>
                {t('tabs.conversion')}
              </StyledTab>
              <StyledTab variant='open' id={ReportsPageTabs.clicks}>
                {t('tabs.clicks')}
              </StyledTab>
            </StyledTabContainer>
          </HeaderTabs>
        </HeaderBody>
      </HeaderContainer>

      <Grid>
        {activeTab === ReportsPageTabs.statistic && (
          <TableStatistics dateFilter={{ from, to }} />
        )}
        {activeTab === ReportsPageTabs.conversion && (
          <TableConversions dateFilter={{ from, to }} />
        )}
        {activeTab === ReportsPageTabs.clicks && (
          <TableClicks dateFilter={{ from, to }} />
        )}
      </Grid>
    </Page>
  );
};

export default ReportsPage;
