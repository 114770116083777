export enum ReportsPageTabs {
  conversion = 'conversion',
  statistic = 'statistic',
  clicks = 'clicks'
}

export interface ReportsTableProps {
  dateFilter: {
    from: string;
    to: string;
  };
}
