import React, { useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableProps } from '../../bit/components/table';

import { useSelector, useDispatch } from 'react-redux';
import {
  selectStatisticsConversions,
  fetchStatisticsConversion
} from '../../redux/slices/statisticsSlice';

import Table from '../Table';
import Offer from '../Offer';
import Typography from '../../bit/components/typography';
import { useQueryParams, withDefault, StringParam } from 'use-query-params';

import useAwaitCallback from '../../hooks/useAwaitCallback';
import {
  ApiStatisticsConversionsGet,
  ApiStatisticFilters
} from '../../types/api/statistics';
import SupportManagerialPageHead from '../../pages/SupportManagerialPage/components/SupportManagerialPageHead';
import {
  HeaderFiltration,
  HeaderFiltrationRow,
  PaginationWrapper
} from '../../pages/ReportsPage/styled';
import OffersDropdown from '../OffersDropdown';
import useStatisticPageSubIds from '../../hooks/useStatisticPageSubIds';
import { DropdownOnChange } from '../Dropdown';
import StatisticPageCountry from '../StatisticPageCountry';
import StatisticPageSubIds from '../StatisticPageSubIds';
import Pagination from '../Pagination';
import { DEFAULT_PAGINATION_PARAMS } from '../../resources/constants';
import Block from '../Block';
import { ReportsTableProps } from '../../pages/ReportsPage/ReportsPageTypes';

const TableConversions = ({ dateFilter: { from, to } }: ReportsTableProps) => {
  const { t } = useTranslation();
  const { data: conversions, meta } = useSelector(selectStatisticsConversions);

  const dispatch = useDispatch();

  const [query, setQuery] = useQueryParams({
    offers: withDefault(StringParam, undefined),
    landing: withDefault(StringParam, undefined),
    countries: withDefault(StringParam, undefined),
    ...DEFAULT_PAGINATION_PARAMS
  });

  const { offers, landing, countries, limit, page } = query;

  const {
    onChangeAffiliateParam,
    onChangeSubId,
    affParam2,
    affParam5,
    subId1,
    subId2,
    subId3,
    subId4,
    subId5
  } = useStatisticPageSubIds();

  const params = useMemo(() => {
    const result: ApiStatisticFilters = {
      offers,
      countries,
      offersLinks: landing,
      affiliatesSubids1: subId1,
      affiliatesSubids2: subId2,
      affiliatesSubids3: subId3,
      affiliatesSubids4: subId4,
      affiliatesSubids5: subId5,
      affiliatesParams5: affParam5
    };
    return result;
  }, [
    countries,
    landing,
    offers,
    subId1,
    subId2,
    subId3,
    subId4,
    subId5,
    affParam5
  ]);

  const [fetchConversions, isLoading] = useAwaitCallback(
    async (params: ApiStatisticsConversionsGet) => {
      await dispatch(fetchStatisticsConversion(params));
    },
    [dispatch]
  );

  useEffect(() => {
    const data: ApiStatisticsConversionsGet = {
      to,
      from,
      page,
      limit,
      ...params
    };

    fetchConversions(data);
  }, [fetchConversions, from, limit, offers, page, params, to]);

  const tableOptions = React.useMemo<TableProps['options']>(
    () => [
      { dataKey: 'date', title: t('conversions.date') },
      { dataKey: 'status', title: t('conversions.status') },
      { dataKey: 'offer', title: t('conversions.offer') },
      { dataKey: 'goal', title: t('conversions.goal') },
      { dataKey: 'payout', title: t('conversions.payout') },
      { dataKey: 'geo', title: t('conversions.geo') }
    ],
    [t]
  );

  const tableRows = React.useMemo<TableProps['rows']>(() => {
    return (conversions || []).map((item, i) => {
      const offerId = parseInt(item.offer);
      const offerTitle = item.offer.replace(String(offerId), '').trim();

      return {
        date: (
          <Typography variant='subtitle2' size={14}>
            {item.date}
          </Typography>
        ),
        goal: (
          <Typography variant='subtitle2' size={14}>
            {item.goal}
          </Typography>
        ),
        payout: (
          <Typography variant='subtitle2' size={14}>
            {item.payout}
          </Typography>
        ),
        status: (
          <Typography variant='subtitle2' size={14}>
            {item.status}
          </Typography>
        ),
        geo: (
          <Typography variant='subtitle2' size={14}>
            {item.geo.country}
          </Typography>
        ),
        offer: <Offer withoutStatus title={offerTitle} offerId={offerId} />,
        key: `clicks-${i}`
      };
    });
  }, [conversions]);

  const createDropdownHandler = useCallback(
    (property: keyof typeof query): DropdownOnChange => (value) => {
      setQuery({ [property]: value || undefined, page: 1 });
    },
    [setQuery]
  );

  return (
    <>
      <Block disablePadding fullWidth>
        <SupportManagerialPageHead>
          <HeaderFiltration>
            <HeaderFiltrationRow>
              <OffersDropdown
                withSavingData
                value={offers}
                landing={landing}
                onChange={createDropdownHandler('offers')}
                onChangeLanding={createDropdownHandler('landing')}
              />
              <StatisticPageCountry
                withSavingData
                value={countries}
                onChange={createDropdownHandler('countries')}
              />
            </HeaderFiltrationRow>

            <HeaderFiltrationRow>
              <StatisticPageSubIds
                subId1={subId1}
                subId2={subId2}
                subId3={subId3}
                subId4={subId4}
                subId5={subId5}
                affParam2={affParam2}
                affParam5={affParam5}
                onChange={onChangeSubId}
                onChangeAffiliateParam={onChangeAffiliateParam}
              />
            </HeaderFiltrationRow>
          </HeaderFiltration>
        </SupportManagerialPageHead>

        <Table
          fullWidth
          rows={tableRows}
          isLoading={isLoading}
          options={tableOptions}
          isEmpty={!conversions?.length}
        />
      </Block>

      <PaginationWrapper>
        <Pagination
          page={page}
          pages={meta?.pages}
          onChange={(nextPage) => setQuery({ page: nextPage })}
          limitProps={{
            value: limit,
            onChange: (newLimit) =>
              setQuery({ limit: Number(newLimit), page: 1 })
          }}
        />
      </PaginationWrapper>
    </>
  );
};

export default TableConversions;
